import { AbstractControl, ValidationErrors } from '@angular/forms';
import { PhoneInputModel } from '../component/phone-input/phone-input.model';
import lpn from 'google-libphonenumber';

export function validatePhoneNumber(control: AbstractControl): ValidationErrors {
  const value = control.value as PhoneInputModel;
  if (!value?.number) {
    return {};
  }
  const error = { validatePhoneNumber: { valid: false } };

  const phoneUtil = lpn.PhoneNumberUtil.getInstance();
  try {
    const e164Number = value.e164Number || value.dialCode + value.number;
    const phoneNumber = phoneUtil.parse(e164Number, value.countryCode);
    if (!phoneUtil.isValidNumberForRegion(phoneNumber, value.countryCode)) {
      return error;
    }
  } catch (e) {
    console.debug(e);
    return error;
  }

  return {};
}
